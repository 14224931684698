import React, {useState, useEffect} from "react";
import {Row, Col, Typography, Button, Select, Input, Upload, message, Switch, Image as AntImage, Spin, Tag, Checkbox } from "antd";
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { InboxOutlined } from '@ant-design/icons';
import axios from "axios";
import { useTranslation} from 'react-i18next';
import * as nsfwjs from 'nsfwjs';
import PubSub from 'pubsub-js';

import ChatBox from "../../components/ChatBox"
import "./ChatPage.css"
import checkLogin from "../../components/CheckLogin";

const { Title, Paragraph } = Typography;
const { Option } = Select;
const { Dragger } = Upload;

function ChatPage(props) {
    const location = useLocation();
    const [isUpload, setIsUpload] = useState(false);
    const [imageSrc, setImageSrc] = useState(null);
    const [originalSrc, setOriginalSrc] = useState(null);
    const [conditionalSrc, setConditionalSrc] = useState(null);
    const [originalImage, setOriginalImage] = useState(null);
    const [conditionalImage, setConditionalImage] = useState(null);
    const [image, setImage] = useState(null);
    const [display, setDisplay] = useState(1)
    const [roomType, setRoomType] = useState("living-room");
    const [mode, setMode] = useState("interior")
    const [chatMode, setChatMode] = useState(false);
    const [roomStyle, setRoomStyle] = useState("modern")
    const [furniture, setFurniture] = useState("Sofa")
    const [material, setMaterial] = useState("Wood")
    const navigate = useNavigate();
    const [msg, setMsg] = useState(null);
    const [roomId, setRoomId] = useState(null);
    const loginInfo = JSON.parse(localStorage.getItem('loginInfo'))
    const {pro, email, credit} = loginInfo;
    const [originalLoading, setOriginalLoading] = useState(true);
    const { t } = useTranslation();
    const [targetLoading, setTargetLoading] = useState(true);
    const [selectedOptions, setSelectedOptions] = useState(null);
    const [options, setOptions] = useState(null);
    const [resolution, setResolution] = useState(0);
    const [isWait, setIsWait] = useState(true);
    const [waitMessage, setWaitMessage] = useState('');
    const [intervalId, setIntervalId] = useState(null);
    const [isChecking, setIsChecking] = useState(true);

  const styles = {
      "modern": t('Modern'),
      "minimalist": t('Minimalist'),
      "chinese": t('Chinese'),
      "European": t('European'),
      "American": t('American'),
      "industrial": t('Industrial'),
      "nordic": t('Nordic'),
      "Japanese": t('Japanese'),
      "mid-century modern": t('Mid-century modern'),
      "luxury": t("Luxury"),
      "cyberpunk": t("cyberpunk")
  };

  const furnitures = {
    "Sofa": t('Sofa'),
    "Table": t('Table'),
    "Chair": t('Chair'),
    "Bed": t('Bed'),
    "Wardrobe": t('Wardrobe'),
    "Bookcase": t('Bookcase'),
    "Dining Table": t('Dining Table'),
    "Coffee Table": t('Coffee Table'),
    "Cabinet": t('Cabinet'),
    "Drawer": t('Drawer'),
    "Mirror": t('Mirror'),
    "Nightstand": t('Nightstand'),
    "Shoe Cabinet": t('Shoe Cabinet'),
    "Display Cabinet": t('Display Cabinet'),
    "Desk": t('Desk'),
    "Conference Table": t('Conference Table'),
    "Counter": t('Counter'),
    "Sofa Bed": t('Sofa Bed'),
    "Bar Table": t('Bar Table'),
    "Cash Desk": t('Cash Desk')
  };

  const materials = {
    "Wood": t('Wood'),
    "Metal": t('Metal'),
    "Glass": t('Glass'),
    "Acrylic": t('Acrylic'),
    "Plastic": t('Plastic'),
    "Leather": t('Leather'),
    "Fabric": t('Fabric'),
    "Stone": t('Stone'),
    "Concrete": t('Concrete'),
    "Bamboo": t('Bamboo'),
    "Rattan": t('Rattan'),
    "Particle board": t('Particle board'),
    "MDF": t('MDF'),
    "Plywood": t('Plywood'),
    "Foam": t('Foam'),
    "Vinyl": t('Vinyl'),
    "Marble": t('Marble'),
    "Slate": t('Slate'),
    "Wicker": t('Wicker'),
    "Cork": t('Cork')
  };

  const [negativeElements, setNegativeElements] = useState('');
  const [postiveElements, setPositiveElements] = useState("");
  const [switchState, setSwitchState] = useState(true);
  const [fileList, setFileList] = useState([]);
  const [fileList2, setFileList2] = useState([]);

  const handleSwitchChange = (checked) => {
    setSwitchState(checked);
  }

  const handleChangeDisplay = (value) => {
      setDisplay(value);
    }

    const handleOptionChange = (option) => {
        setSelectedOptions((prevSelectedOptions) => {
          if (prevSelectedOptions.includes(option)) {
            return prevSelectedOptions.filter((item) => item !== option);
          } else {
            return [...prevSelectedOptions, option];
          }
        });
    };

  const onChangeUImode = (checked: boolean) => {
      if (checked) {
          const {email} = loginInfo
            axios({
              method: "post",
              url: '/chatinterior/get_dialog',
              data: {
                  "user": email,
                  "roomId": location.state.roomId
              },
            }).then(function (response) {
                const data = response.data;
                if (data.success) {
                    setMsg(data.dialog);
                    setChatMode(checked);
                } else {
                    message.error(data.message, 3);
                    setTimeout(() => {
                        localStorage.removeItem('loginInfo'); // 清除 localStorage 中的用户信息
                        navigate("/login");
                    }, 3000);
                }
            }).catch(function (error) {
              message.error("The service is down, please try again later!", 3);
            })
      }
  };

  const handleChangePositive = (event) => {
    setPositiveElements(event.target.value);
  };

  const handleChangeNegative = (event) => {
    setNegativeElements(event.target.value);
  };

  function handleChangeRoom(value) {
    setRoomType(value);
  };

  function handleChangeMode(value) {
    setMode(value);
  };

  function handleChangeStyle(value) {
    setRoomStyle(value);
  };

  function handleChangeFurniture(value) {
    setFurniture(value);
  };

  function handleChangeMaterial(value) {
    setMaterial(value);
  };

  function handleChangeResolution(value){
      setResolution(value);
  }

  function TurnBack () {
        setTargetLoading(true);
        const {email} = loginInfo
        axios({
          method: "post",
          url: '/chatinterior/turnback',
          data: {
              "user": email,
              "roomId": location.state.roomId
          },
        }).then(async function (response) {
            const data = response.data;
            if (data.status === 200) {
                setImageSrc(data.imageUrl);
                setImage("https://www.aidearoom.com/" + data.imageUrl);
                setTargetLoading(false);
            } else {
                setTargetLoading(false);
                message.error(data.message, 3);
            }
        }).catch(function (error) {
            setTargetLoading(false);
            message.error("There is something wrong, please try again later!", 3);
        })
  }

  const handleRemove = (file) => {
      setOriginalSrc(null);
    };


  const handleRemove2 = (file) => {
      setConditionalSrc(null);
    };

   const handleChange = ({ fileList }) => {
      if (fileList.length > 0) {
          setFileList([fileList[fileList.length-1]]);
      } else {
          setFileList([]);
      }
  };
   const handleChange2 = ({ fileList }) => {
      if (fileList.length > 0) {
          setFileList2([fileList[fileList.length-1]]);
      } else {
          setFileList2([]);
      }
  };
  const beforeUpload = (file) => {
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        message.error('You can only upload images smaller than 5MB!');
        setFileList([]);
        return false;
      } else {
            setOriginalSrc(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setOriginalImage(reader.result);
                message.success(`${file.name} Uploaded successfully！`);
            };
            reader.onerror = (e) => {
                message.success(`${file.name} Uploaded Failed！`);
            };
            reader.readAsDataURL(file);
          // nsfwjs.load()
          // .then(async function (model) {
          //   // Classify the image
          //   const img = new Image();
          //     img.src = URL.createObjectURL(file);
          //
          //     await new Promise((resolve) => {
          //       img.onload = resolve;
          //     });
          //   return model.classify(img)
          // }).then(function (predictions) {
          //   const hasNeutralItemWithProbability = predictions.some(
          //       (item) => item.className === 'Neutral' && item.probability > 0.2
          //     );

            // if (!hasNeutralItemWithProbability) {
            //     setFileList([]);
            //     message.error('图片包含敏感内容，请上传其他图片！', 2);
            // } else {
            // setOriginalSrc(file);
            // const reader = new FileReader();
            // reader.onloadend = () => {
            //     setOriginalImage(reader.result);
            // };
            // reader.readAsDataURL(file);
            // message.success(`${file.name} Uploaded successfully！`);
            // }
          // })
      }
    return false;
  }

  const beforeUpload2 = (file) => {
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        message.error('You can only upload images smaller than 5MB!');
        setFileList([]);
        return false;
      } else {
        setConditionalSrc(file);
        const reader = new FileReader();
        reader.onloadend = () => {
            setConditionalImage(reader.result);
        };
        reader.readAsDataURL(file);
        message.success(`${file.name} Uploaded successfully！`);
      }
    return false;
  }

  async function upload() {
      if (credit <= 0) {
          message.info("Your have no credit left, please obtain more.", 2)
          return
      }
      if (mode === 'fusion' && conditionalSrc === null){
          message.info('Please upload the reference room image!', 2)
          return
      }
      else if (originalSrc || roomId || mode === "imagine") {
          setIsUpload(true);
          setIsWait(true);
          if (mode === "imagine" && !roomId) {
              setOriginalLoading(true);
          } else {
              setOriginalLoading(false)
          }
          setTargetLoading(true);
          const formData = new FormData();
            formData.append("mode", mode)
            formData.append('roomType', roomType);
            if (roomId) {
                formData.append("roomId", roomId)
            }
            formData.append("roomStyle", roomStyle);
            formData.append("furniture", furniture);
            formData.append("material", material);
            formData.append("postiveElements", postiveElements);
            formData.append("negativeElements", negativeElements);
            formData.append("public", display)
            formData.append("user", email);
            formData.append("credit", credit);
            formData.append("resolution", resolution);
            if (options === null) {
                formData.append("chosen_name", JSON.stringify([]))
            } else {
                formData.append("chosen_name", JSON.stringify(selectedOptions))
            }
            if (originalSrc && !roomId) {
              formData.append('image', originalSrc);
              if (mode === 'fusion' || mode === 'furniture') {
                  formData.append('condition_image', conditionalSrc);
              }
            }
            axios({
              method: "post",
              url: '/chatinterior/upload',
              headers: {
                'Content-Type': 'multipart/form-data'
              },
              data: formData,
            }).then(async function (response) {
                const data = response.data;
              if (data.status === 200) {
                setImageSrc(data.image);
                const TargetResponse = await fetch('/chatinterior/image/' + data.image);
                setImage(URL.createObjectURL(await TargetResponse.blob()));
                setTargetLoading(false);
                setOptions(data.object_name);
                setSelectedOptions(data.object_name);
                if (data.origin_url !== "") {
                    const OriginalResponse = await fetch('/chatinterior/image/' + data.origin_url);
                    setOriginalImage(URL.createObjectURL(await OriginalResponse.blob()));
                    setOriginalLoading(false);
                }
                setRoomId(data.roomId);
                setMode("interior");
                loginInfo.credit = loginInfo.credit - 1;
                localStorage.setItem('loginInfo', JSON.stringify(loginInfo));
                PubSub.publish('Quota Status', loginInfo.credit);
              } else if (data.status == 201) {
                setWaitMessage(data.message);
                const params = { filepath: data.img_url, userId: email, roomId: data.roomId };
                const initialIntervalId = setTimeout(() => {
                    callApi(params)
                }, 5000);
                setIntervalId(initialIntervalId);
              } else {
                message.error(data.message, 3)
                setTimeout(() => {
                    navigate("/design");
                  }, 3000); // 等待1秒后重新载入页面
              }
            }).catch(function (error) {
              message.error("Sorry! Something wrong with service, please try again later.", 3);
              setTimeout(() => {
                navigate("/design");
              }, 3000); // 等待1秒后重新载入页面
            })
      } else {
          message.info("Please upload your room image！", 2)
      }
  };

  const callApi = (params) => {
    const queryString = Object.keys(params)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
        .join('&');
    const url = `/chatinterior/image?${queryString}`;  // 替换为你的API端点
    axios({
        method: "get",
        url: url,
    }).then(function (TargetResponse) {
        const contentType = TargetResponse.headers.get('Content-Type');
        if (contentType === 'image/jpeg') {
            const object_name = TargetResponse.headers.get("object_name");
            if (object_name !== 'None') {
                setOptions(object_name.split("\t"));
                setSelectedOptions(object_name.split("\t"));
            } else {
                setOptions([]);
                setSelectedOptions([]);
            }
            setImage("https://www.aidearoom.com/" + params.filepath);
            if (originalImage === null) {
                setOriginalImage("https://www.aidearoom.com/" + params.filepath);
                setOriginalLoading(false);
            }
            loginInfo.credit = TargetResponse.headers.get('balance');
            localStorage.setItem('loginInfo', JSON.stringify(loginInfo));
            PubSub.publish('Quota Status', loginInfo.credit);
            setTargetLoading(false);
            setIsWait(false);
            clearInterval(intervalId); // 停止循环
        } else {
            const data2 = TargetResponse.data;
            setWaitMessage(data2.message);
            if (data2.status === 201) {
                const newIntervalId = setTimeout(() => {
                    callApi(params)
                }, 5000); // 根据新的循环时间设置新的定时器
                clearTimeout(intervalId);
                setIntervalId(newIntervalId);
            } else {
                clearTimeout(intervalId);
            }
        }
    }).catch(function (error) {
        setTimeout(() => {
            navigate("/design");
          }, 3000); // 等待1秒后重新载入页面
        console.log(error);
        message.error("Sorry, something wrong. Please try again later！", 3);
    })
  };

  useEffect(() => {
    const loginStatus = checkLogin();
    window.scrollTo(0, 0);

    if (!loginStatus) {
      navigate("/login")
    } else {
      if (location.state) {
        setIsUpload(true);
        setRoomType(location.state.roomInfo.room_type);
        setRoomId(location.state.roomId);
        setRoomStyle(location.state.roomInfo.room_style);
        if (location.state.roomInfo.object_name !== null) {
            const object_name = location.state.roomInfo.object_name.split("\t");
            setOptions(object_name);
            setSelectedOptions(object_name);
        } else {
            setOptions([]);
            setSelectedOptions([]);
        }
        async function fetchData() {
            setOriginalImage("https://www.aidearoom.com/" + location.state.roomInfo.origin_url);
            setOriginalLoading(false);
            const params2 = { filepath: location.state.roomInfo.image_url, userId: email, roomId: location.state.roomId };
            const queryString2 = Object.keys(params2)
                .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params2[key])}`)
                .join('&');
            const url2 = `/chatinterior/image?${queryString2}`;  // 替换为你的API端点
            const TargetResponse = await fetch(url2);
            const contentType = TargetResponse.headers.get('Content-Type');
            if (contentType === 'image/jpeg') {
                setImage("https://www.aidearoom.com/" + location.state.roomInfo.image_url);
                setTargetLoading(false);
                setIsWait(false);
            } else {
                const data = await TargetResponse.json();
                if (data.status === 201) {
                    setWaitMessage(data.message);
                    const params = { filepath: data.image_url, userId: email, roomId: data.roomId };
                    const initialIntervalId = setTimeout(() => {
                        callApi(params2)
                    }, 5000); // 初始循环时间为 30 秒
                    setIntervalId(initialIntervalId);
                } else {
                    message.error(data.message, 3);
                    setTimeout(() => {
                        navigate("/design");
                    }, 3000); // 等待1秒后重新载入页面
                }
            }
        }
        fetchData();
      }
      if (window.innerWidth < 576) {
          document.getElementById("footer").style.display = "none";
      }
    }
    return () => {
        clearTimeout(intervalId);
    };

  }, []);

  return (
      <div className="container" style={{marginTop: "80px", padding: window.innerWidth > 576 ? '0 15%': '0'}}>
        {
          isUpload ? (
                  chatMode ? (
                      <Row justify="center" className="row">
                          <Col span={24} align="middle" style={{marginBottom: "20px"}}>
                                <Switch defaultChecked  size="small" onChange={onChangeUImode} /> Open Chat Mode
                            </Col>
                          <ChatBox ImgSrc={imageSrc} roomType={roomType} mode={mode} style={roomStyle} msg={msg} roomId={roomId}
                                   postiveElements={postiveElements}
                                   negativeElements={negativeElements}/>
                      </Row>
                  ) : (
                      <Row justify="center" className="row">
                        <Col span={24} align="middle">
                            <Title level={1} id="chat-title" style={{color: 'white'}}>{t("Remodel-title")}</Title>
                        </Col>
                          {
                              window.innerWidth > 576 && (
                                  <Col span={24} align="middle">
                                    <Paragraph style={{fontSize: "20px", color: "gray"}}>
                                        {t("Remodel-content")}
                                    </Paragraph>
                                </Col>
                              )
                          }
                          {/*{*/}
                          {/*    pro && (*/}
                          {/*         <Col span={24} align="middle">*/}
                          {/*              <Switch size="small" onChange={onChangeUImode}/> Open Chat Mode*/}
                          {/*          </Col>*/}
                          {/*    )*/}
                          {/*}*/}
                          {
                             window.innerWidth > 576 ? (
                                 <Col span={24}>
                                     <Row justify="center" className="row" gutter={[16, 16]}>
                                         <AntImage.PreviewGroup
                                            preview={{
                                              onChange: (current, prev) => console.log(`current index: ${current}, prev index: ${prev}`),
                                            }}
                                          >
                                             <Col md={24} lg={12} align="middle" algin="middle">
                                                 <Col span={24} style={{fontSize: "20px", marginBottom: "10px"}}>
                                                    <label >{t("Original")}</label>
                                                 </Col>
                                                 {
                                                     originalLoading ? (
                                                         <Row className="loading">
                                                             <Spin size="large"/>
                                                         </Row>
                                                     ) : (
                                                         <AntImage src={originalImage} width={500} height={400}   placeholder={true}/>
                                                     )
                                                 }
                                             </Col>
                                             <Col md={24} lg={12} align="middle" style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                                 { isWait ? (
                                                     <div>
                                                         <Paragraph style={{color: "white", fontSize: 24}}>
                                                             {waitMessage}
                                                         </Paragraph>
                                                         <Spin size="large"/>
                                                     </div>
                                                 ) : (
                                                     <div>
                                                         <Col span={24} style={{fontSize: "20px", marginBottom: "10px"}}>
                                                            <label >{t("Generated")}</label>
                                                         </Col>
                                                         {
                                                             targetLoading ? (
                                                                 <Row className="loading">
                                                                     <Spin size="large"/>
                                                                 </Row>
                                                             ) : (
                                                                 <AntImage src={image} width={500}  height={400} placeholder={true}/>
                                                             )
                                                         }
                                                     </div>
                                                 )}
                                             </Col>
                                         </AntImage.PreviewGroup>
                                     </Row>
                                 </Col>
                             ): (
                                     <Row justify="center" className="row">
                                         <Col span={24} align="middle">
                                             {switchState ? (
                                                 isWait ? (
                                                     <div style={{padding: 10}}>
                                                         <Paragraph style={{color: "white", fontSize: 24}}>
                                                             {waitMessage}
                                                         </Paragraph>
                                                         <Spin size="large"/>
                                                     </div>
                                                 ) : (
                                                     targetLoading ? (
                                                         <Row className="loading">
                                                             <Spin size="large"/>
                                                         </Row>
                                                     ) : (
                                                         <AntImage src={image}  style={{borderRadius: "5px"}} width={window.innerWidth * 0.95} height={300} placeholder={true}/>
                                                     )
                                                 )
                                              ) : (
                                                 originalLoading ? (
                                                     <Row className="loading">
                                                         <Spin size="large"/>
                                                     </Row>
                                                 ) : (
                                                     <AntImage src={originalImage}  style={{borderRadius: "5px"}} width={window.innerWidth * 0.95} height={300} placeholder={true}/>
                                                 )
                                              )}
                                        </Col>
                                         <Col span={24} align="middle" style={{marginTop: "10px"}}>
                                              {t("Original")}  <Switch checked={switchState} onChange={handleSwitchChange}/>  {t("Generated")}
                                          </Col>
                                     </Row>
                            )
                          }
                          {
                              (!furnitures.hasOwnProperty(roomType) && mode !== 'furniture') && (
                                  <div style={{width: "100%"}}>
                                      <Row justify="center" gutter={window.innerWidth > 576 ? [48, 16] : [32: 8]} style={{width: "100%"}}>
                                          <Col span={window.innerWidth > 576 ? 12 : 24} align="middle">
                                              <Col span={24} style={{fontSize: "20px"}  }>
                                                <label className="select-label">{t("room type")}</label>
                                              </Col>
                                              <Col span={24} align="middle">
                                                  <Select defaultValue={roomType} className="edit-select"
                                                          size={window.innerWidth > 576 ? "large" : "middle"} onChange={handleChangeRoom}>
                                                    <Option value="bedroom">{t("bedroom")}</Option>
                                                    <Option value="bathroom">{t("bathroom")}</Option>
                                                    <Option value="kitchen">{t("kitchen")}</Option>
                                                    <Option value="dining-room">{t("dining-room")}</Option>
                                                    <Option value="living-room">{t("living-room")}</Option>
                                                    <Option value="study-room">{t("study-room")}</Option>
                                                    <Option value="balcony">{t("balcony")}</Option>
                                                    <Option value="garage">{t("garage")}</Option>
                                                    <Option value="garden">{t("garden")}</Option>
                                                    <Option value="conference-room">{t("conference-room")}</Option>
                                                    <Option value="reception-room">{t("reception-room")}</Option>
                                                    <Option value="office">{t("office")}</Option>
                                                    <Option value="game-room">{t("game-room")}</Option>
                                                    <Option value="home-theat">{t("home-theat")}</Option>
                                                    <Option value="kids-room">{t("kids-room")}</Option>
                                                    <Option value="basement">{t("basement")}</Option>
                                                  </Select>
                                              </Col>
                                              <Col span={24} style={{fontSize: "20px"}  }>
                                                <label className="select-label">{t("room style")}</label>
                                              </Col>
                                              <Col span={24} align="middle">
                                                  <Select className="edit-select"
                                                          defaultValue={roomStyle}
                                                          size={window.innerWidth > 576 ? "large" : "middle"}
                                                          onChange={handleChangeStyle}>
                                                    {Object.entries(styles).map(([key, value]) => (
                                                        <Option key={key} value={key}>
                                                          {value}
                                                        </Option>
                                                    ))}
                                                  </Select>
                                              </Col>
                                              <Col span={24} style={{fontSize: "20px"}  }>
                                                <label className="select-label">{t("resolution")}</label>
                                              </Col>
                                              <Col span={24} align="middle">
                                                  <Select defaultValue={resolution === 1 ? t("high-resolution") : t("low-resolution")} className="edit-select"
                                                          size={window.innerWidth > 576 ? "large" : "middle"} onChange={handleChangeResolution}>
                                                    <Option value="0">{t("low-resolution")}</Option>
                                                    <Option value="1" disabled={pro === 0}>{t("high-resolution")}</Option>
                                                  </Select>
                                              </Col>
                                          </Col>
                                          <Col span={window.innerWidth > 576 ? 12 : 24} align="middle" align="middle">
                                              <Col span={24} style={{fontSize: "20px"}  }>
                                                <label className="select-label">{t("object selction")}</label>
                                              </Col>
                                              <Col span={24} align="middle">
                                                  <div className="selected-box" style={(options === null ||options.length === 0) ? {width: "100%"} : {width: "fit-content"}}>
                                                    {options !== null ? (
                                                        <div>
                                                            {options.length > 0 && options.map((option) => (
                                                              <Tag
                                                                  key={option}
                                                                  style={{ background: 'orange', height: "fit-content"}}
                                                              >
                                                                <Checkbox defaultChecked={true} key={option} onChange={() => handleOptionChange(option)}>
                                                                  {option}
                                                                </Checkbox>
                                                              </Tag>
                                                            ))}
                                                        </div>
                                                        ) : (
                                                        <Row className="loading" style={{width: "100%", height: 100}}>
                                                             <Spin />
                                                        </Row>
                                                    )}
                                                  </div>
                                              </Col>
                                          </Col>
                                  </Row>
                                      <Col span={24} align="middle" style={{marginTop: "15px"}}>
                                          <Button type="primary" disabled={targetLoading || originalLoading} size={window.innerWidth > 576 ? "large" : "normal"} onClick={upload} style={{marginRight: "30px"}}>{t("remodel")}</Button>
                                          <Button size={window.innerWidth > 576 ? "large" : "normal"} disabled={targetLoading || originalLoading} onClick={TurnBack}>{t("turnback")}</Button>
                                        </Col>
                                  </div>
                              )
                          }
                    </Row>
                  )
          ) : (
              <Row justify="center" className="row">
                <Col span={24} align="middle">
                  <Title level={1} id="chat-title" style={{color: 'white'}}>{t("upload title")}</Title>
                    {
                        window.innerWidth > 576 && (
                            <Col span={24} align="middle">
                                <Paragraph style={{fontSize: "20px", color: "gray"}}>
                                    Find your history room in <Link to='/design'>bulter</Link>. Obtain more <Link to='/usage'>credits</Link> here.
                                </Paragraph>
                            </Col>
                        )
                    }
                </Col>
                <Col span={24} align="middle">
                  <Col span={24} style={{fontSize: "20px"}}>
                    <label className="select-label">{t("mode")}</label>
                  </Col>
                  <Select value={t("Interior design")} className="upload-select"
                          size={window.innerWidth > 576 ? "large" : "middle"} onChange={handleChangeMode}>
                    <Option value="imagine">{t("image mode")}</Option>
                    <Option value="interior">{t("Interior design")}</Option>
                    <Option value="fusion">{t("fusion design")}</Option>
                    <Option value="furniture">{t("furniture transfer")}</Option>
                  </Select>
                </Col>
                  {
                      mode === 'furniture' ? (
                          <div style={{width: "100%"}}>
                              <Col span={24} align="middle">
                                  <Col span={24} style={{fontSize: "20px"}}>
                                    <label className="select-label">{t("furniture")}</label>
                                  </Col>
                                  <Select className="upload-select"
                                          value={furniture}
                                          size={window.innerWidth > 576 ? "large" : "middle"}
                                          onChange={handleChangeFurniture}>
                                    {Object.entries(furnitures).map(([key, value]) => (
                                        <Option key={key} value={key}>
                                          {value}
                                        </Option>
                                    ))}
                                  </Select>
                                </Col>
                                <Col span={24} align="middle">
                                  <Col span={24} style={{fontSize: "20px"}  }>
                                    <label className="select-label">{t("material")}</label>
                                  </Col>
                                  <Select className="upload-select"
                                          value={material}
                                          size={window.innerWidth > 576 ? "large" : "middle"}
                                          onChange={handleChangeMaterial}>
                                    {Object.entries(materials).map(([key, value]) => (
                                        <Option key={key} value={key}>
                                          {value}
                                        </Option>
                                    ))}
                                  </Select>
                                </Col>
                          </div>
                      ) : (
                          <div style={{width: "100%"}}>
                              <Col span={24} align="middle">
                                  <Col span={24} style={{fontSize: "20px"}}>
                                    <label className="select-label">{t("room type")}</label>
                                  </Col>
                                  <Select value={roomType} className="upload-select"
                                          size={window.innerWidth > 576 ? "large" : "middle"} onChange={handleChangeRoom}>
                                       <Option value="bedroom">{t("bedroom")}</Option>
                                        <Option value="bathroom">{t("bathroom")}</Option>
                                        <Option value="kitchen">{t("kitchen")}</Option>
                                        <Option value="dining-room">{t("dining-room")}</Option>
                                        <Option value="living-room">{t("living-room")}</Option>
                                        <Option value="study-room">{t("study-room")}</Option>
                                        <Option value="balcony">{t("balcony")}</Option>
                                        <Option value="garage">{t("garage")}</Option>
                                        <Option value="garden">{t("garden")}</Option>
                                        <Option value="conference-room">{t("conference-room")}</Option>
                                        <Option value="reception-room">{t("reception-room")}</Option>
                                        <Option value="office">{t("office")}</Option>
                                        <Option value="game-room">{t("game-room")}</Option>
                                        <Option value="home-theat">{t("home-theat")}</Option>
                                        <Option value="kids-room">{t("kids-room")}</Option>
                                        <Option value="basement">{t("basement")}</Option>
                                  </Select>
                                </Col>
                                                <Col span={24} align="middle">
                                  <Col span={24} style={{fontSize: "20px"}  }>
                                    <label className="select-label">{t("room style")}</label>
                                  </Col>
                                  <Select className="upload-select"
                                          value={roomStyle}
                                          size={window.innerWidth > 576 ? "large" : "middle"}
                                          onChange={handleChangeStyle}>
                                    {Object.entries(styles).map(([key, value]) => (
                                        <Option key={key} value={key}>
                                          {value}
                                        </Option>
                                    ))}
                                  </Select>
                            </Col>
                          </div>
                      )
                  }
                  <Col span={24} align="middle">
                      <Col span={24} style={{fontSize: "20px"}  }>
                        <label className="select-label">{t("resolution")}</label>
                      </Col>
                      <Select value={t("low-resolution")} className="upload-select"
                              size={window.innerWidth > 576 ? "large" : "middle"} onChange={handleChangeResolution}>
                        <Option value="0">{t("low-resolution")}</Option>
                        <Option value="1" disabled={pro === 0}>{t("high-resolution")}</Option>
                      </Select>
                    </Col>
                  <Col span={24} align="middle">
                      <Col span={24} style={{fontSize: "20px"}}>
                        <label className="select-label">{t("Permission")}</label>
                      </Col>
                      <Select value={t("public")} className="upload-select"
                              size={window.innerWidth > 576 ? "large" : "middle"} onChange={handleChangeDisplay}>
                        <Option value="1">{t("public")}</Option>
                        <Option value="0" disabled={pro === 0}>{t("non-public")}</Option>
                      </Select>
                    </Col>
                {
                    mode === "interior filling" && (
                        <Col span={24} align="middle">
                          <Col xs={24} sm={24} md={24} lg={12} align="middle" className="elements-input">
                            <Col span={24} style={{fontSize: "20px"}}>
                              <label>Interior elements you want</label>
                            </Col>
                            <Input.TextArea value={postiveElements} style={{marginTop: "10px"}}
                                            placeholder="a red sofa, bed, marble table, etc."
                                            onChange={handleChangePositive} autoSize={{minRows: 1, maxRows: 2}}
                                            maxLength={100}/>
                          </Col>
                          <Col xs={24} sm={24} md={24} lg={12} align="middle" className="elements-input">
                            <Col span={24} style={{fontSize: "20px"}}>
                              <label>Interior elements you don't want</label>
                            </Col>
                            <Input.TextArea value={negativeElements} style={{marginTop: "10px"}}
                                            placeholder="a red sofa, bed, marble table, etc."
                                            onChange={handleChangeNegative} autoSize={{minRows: 1, maxRows: 2}}
                                            maxLength={100}/>
                          </Col>
                        </Col>
                    )
                }
                {
                  mode !== "imagine" ? (
                      mode === "interior" ? (
                          <Row>
                            <Col xs={24} sm={24} md={24} lg={24} align="middle">
                                <Col span={24}>
                                    {
                                        (fileList.length > 0 && originalSrc !== null) ? (
                                            <Upload fileList={fileList} listType="picture-card" onChange={handleChange} onRemove={handleRemove}/>
                                        ) : (
                                            <Dragger beforeUpload={beforeUpload} onChange={handleChange}
                                                 accept=".jpg,.png" fileList={fileList} multiple={false}
                                                 showUploadList={false}
                                                  maxCount={1} listType="picture-card">
                                                     <p className="ant-upload-drag-icon">
                                                      <InboxOutlined/>
                                                    </p>
                                                    <Paragraph className="chat-text">{t("upload box")}</Paragraph>
                                            </Dragger>
                                        )
                                    }
                                </Col>
                            </Col>
                            <Col span={24} align="middle" style={{marginTop: "15px"}}>
                              <Button type="primary" size="large" onClick={upload}>{t("submit")}</Button>
                            </Col>
                          </Row>
                      ) : (
                              mode === "furniture" ? (
                                  <Row gutter={[16,16]}>
                            <Col xs={24} sm={24} md={12} lg={12} align="middle">
                                <Col span={24} style={{fontSize: "20px", marginBottom: "5px"}}>
                                    <label className="select-label">{t("subject image")}</label>
                                  </Col>
                                <Col span={24}>
                                    {
                                        (fileList.length > 0 && originalSrc !== null) ? (
                                            <Upload fileList={fileList} listType="picture-card" onChange={handleChange} onRemove={handleRemove}/>
                                        ) : (
                                            <Dragger beforeUpload={beforeUpload} onChange={handleChange}
                                                 accept=".jpg,.png" fileList={fileList} multiple={false}
                                                 showUploadList={false}
                                                  maxCount={1} listType="picture-card">
                                                     <p className="ant-upload-drag-icon">
                                                      <InboxOutlined/>
                                                    </p>
                                                    <Paragraph className="chat-text">{t("upload box")}</Paragraph>
                                            </Dragger>
                                        )
                                    }
                                </Col>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} align="middle">
                                <Col span={24} style={{fontSize: "20px", marginBottom: "5px"}}>
                                    <label className="select-label">{t("material image")}</label>
                                  </Col>
                                <Col span={24}>
                                {
                                    (fileList2.length > 0 && conditionalSrc !== null) ? (
                                        <Upload fileList={fileList2} listType="picture-card" onChange={handleChange2} onRemove={handleRemove2}/>
                                    ) : (
                                        <Dragger beforeUpload={beforeUpload2} onChange={handleChange2}
                                             accept=".jpg,.png" fileList={fileList2} multiple={false}
                                             showUploadList={false}
                                              maxCount={1} listType="picture-card">
                                                 <p className="ant-upload-drag-icon">
                                                  <InboxOutlined/>
                                                </p>
                                                <Paragraph className="chat-text">{t("upload box")}</Paragraph>
                                        </Dragger>
                                    )
                                }
                                </Col>
                            </Col>
                            <Col span={24} align="middle" style={{marginTop: "15px"}}>
                              <Button type="primary" size="large" onClick={upload}>{t("submit")}</Button>
                            </Col>
                          </Row>
                            ) : (
                                <Row gutter={[16,16]}>
                            <Col xs={24} sm={24} md={12} lg={12} align="middle">
                                <Col span={24} style={{fontSize: "20px", marginBottom: "5px"}}>
                                    <label className="select-label">{t("origin_url")}</label>
                                  </Col>
                                <Col span={24}>
                                    {
                                        (fileList.length > 0 && originalSrc !== null) ? (
                                            <Upload fileList={fileList} listType="picture-card" onChange={handleChange} onRemove={handleRemove}/>
                                        ) : (
                                            <Dragger beforeUpload={beforeUpload} onChange={handleChange}
                                                 accept=".jpg,.png" fileList={fileList} multiple={false}
                                                 showUploadList={false}
                                                  maxCount={1} listType="picture-card">
                                                     <p className="ant-upload-drag-icon">
                                                      <InboxOutlined/>
                                                    </p>
                                                    <Paragraph className="chat-text">{t("upload box")}</Paragraph>
                                            </Dragger>
                                        )
                                    }
                                </Col>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} align="middle">
                                <Col span={24} style={{fontSize: "20px", marginBottom: "5px"}}>
                                    <label className="select-label">{t("reference_url")}</label>
                                  </Col>
                                <Col span={24}>
                                {
                                    (fileList2.length > 0 && conditionalSrc !== null) ? (
                                        <Upload fileList={fileList2} listType="picture-card" onChange={handleChange2} onRemove={handleRemove2}/>
                                    ) : (
                                        <Dragger beforeUpload={beforeUpload2} onChange={handleChange2}
                                             accept=".jpg,.png" fileList={fileList2} multiple={false}
                                             showUploadList={false}
                                              maxCount={1} listType="picture-card">
                                                 <p className="ant-upload-drag-icon">
                                                  <InboxOutlined/>
                                                </p>
                                                <Paragraph className="chat-text">{t("upload box")}</Paragraph>
                                        </Dragger>
                                    )
                                }
                                </Col>
                            </Col>
                            <Col span={24} align="middle" style={{marginTop: "15px"}}>
                              <Button type="primary" size="large" onClick={upload}>{t("submit")}</Button>
                            </Col>
                          </Row>
                              )
                      )
                  ) : (
                      <Col span={24} align="middle">
                        <Button type="primary" size="large" onClick={upload}>{t("generation buttom")}</Button>
                      </Col>
                  )
                }
              </Row>
          )
        }
      </div>
  )
};

export default ChatPage;