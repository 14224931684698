import React, {useState, useEffect} from "react";
import {Button, Row, Col,Typography, Card, message, Spin, Form, Input } from "antd";
import {GoogleOutlined, WechatOutlined, ReloadOutlined } from "@ant-design/icons";
import { useNavigate  } from 'react-router-dom';
import PubSub from 'pubsub-js';
import axios from "axios";
import { useTranslation} from 'react-i18next';
import { useGoogleLogin } from '@react-oauth/google';
import QRCode from 'react-qr-code';


import "./login.css";
const { Title, Paragraph } = Typography;


export default function Login() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const nation = localStorage.getItem("nation")
    const [isLoading, setIsLoading] = useState(true);
    const [qrCode, setQrCode] = useState("");
    const [showQrCode, setShowQrCode] = useState(false)
    const [reload, setReload] = useState(false);
    const userinfo = localStorage.getItem('loginInfo');

     const login = useGoogleLogin({
      onSuccess: codeResponse => handleLogin(codeResponse),
    });

    const [loginLoading, setLoginLoading] = useState(false);

    const onFinish = async (values) => {
        setLoginLoading(true);
        try {
          // 假设后端的登录API URL是 `http://localhost:4000/api/login`
          const response = await axios.post('/chatinterior/login', {
            email: values.username,
            password: values.password
          });
          const data = response.data;
          if (data.status == 200) {
              const userInfo = {
                "email": data.userInfo['user_id'],
                "avatarUrl": data.userInfo['avatar_url'],
                "nickName": data.userInfo['nickname'],
                "pro": data.userInfo["pro"],
                "credit": data.userInfo["credit"],
                "expriedDate": new Date(Date.now() + 7 * 24 * 60 * 60 * 1000),
                "memberExpiredDate": data.userInfo["expired_date"]
            }
            localStorage.setItem('loginInfo', JSON.stringify(userInfo));
            // 跳转到主页或其他需要登录的页面
            PubSub.publish('Login Status', true);
            message.success(data.message);
            navigate("/chat")
          }  else {
              message.error(data.message)
          }
          // console.log('Server response:', response.data);
          // 可以在这里处理登录后的逻辑，例如页面跳转或状态更新等
        } catch (error) {
          message.error('Login Failed! Please check your user email and password!');
          // console.error('Login error:', error);
        }
        setLoginLoading(false);
    };

    async function handleLogin(codeResponse) {
        const accessToken = codeResponse.access_token
        const userResponse = await axios.get('https://www.googleapis.com/oauth2/v1/userinfo?access_token='+accessToken)
        const {email, name, picture} = userResponse.data
        // const email = 'test'
        // const name = 'test'
        // const picture = 'https://www.aidearoom.com/logo.png'
        // const accessToken = 'test'
        const backResponse = await axios.post('/chatinterior/login', {
            "email": email,
            "nickName": name,
            "avatorUrl": picture,
            "accessToken": accessToken
        });
          const data = backResponse.data;
          // console.log(data)
          if (data.status === 200) {
            const userInfo = {
                "email": email,
                "avatarUrl": picture,
                "nickName": name,
                "pro": data.userInfo["pro"],
                "credit": data.userInfo["credit"],
                "expriedDate": new Date(Date.now() + 7 * 24 * 60 * 60 * 1000),
                "memberExpiredDate": data.userInfo["expired_date"]
            }
            localStorage.setItem('loginInfo', JSON.stringify(userInfo));
            // 跳转到主页或其他需要登录的页面
            PubSub.publish('Login Status', true);
            message.success("Login Successfully!")
            navigate("/chat")
          } else {
            message.error("Login Failed! Please try again later!")
          }
    }


    const handleMobileLogin = () => {
        if (userinfo) {
            axios({
                method: "post",
                url: '/chatinterior/login',
                data: {
                  "openid": userinfo["email"]
                },
            }).then(function (response) {
                const data = response.data;
                if (data.status === 200) {
                    const userInfo = {
                        "email": data.userInfo["openid"],
                        "avatarUrl": (data.userInfo["avatar_url"] === "" || data.userInfo["avatar_url"] === null) ?
                            "/default.jpg" : data.userInfo["avatar_url"],
                        "password": data.userInfo["password"],
                        "nickName": (data.userInfo["nickname"] === "" || data.userInfo["nickname"] === null )? "微信用户" : data.userInfo["nickname"],
                        "pro": data.userInfo["pro"],
                        "image_balance": data.userInfo["image_balance"],
                        "text_balance": data.userInfo["text_balance"],
                        "memberExpiredDate": data.userInfo["expiration_date"],
                        "expriedDate": new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)
                    }
                    localStorage.setItem('loginInfo', JSON.stringify(userInfo));
                    PubSub.publish('Login Status', true);
                    message.success("登陆成功！", 2)
                    navigate("/chat")
                    return ;
                }
            }).catch(function (error) {
                message.error('非常抱歉，获取用户信息失败，请稍后再试！', 3);
                return ;
            })
        }
        const encodedUrl = encodeURIComponent("https://www.aidearoom.com")
        const weChatOAuth2Link = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxbb513d3e3e1c9ea5&redirect_uri=${encodedUrl}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
        window.location.href = weChatOAuth2Link;
    };


    async function reloadQrCode() {
        setIsLoading(true);
        setReload(false);
        axios({
              method: "get",
              url: '/chatinterior/qrcode',
              data: {
                  "type": "qrcode",
              },
            }).then(function (response) {
                const data = response.data;
                if (data.type === "qrcode") {
                    setQrCode("data:image/png;base64," + data.qrcode);
                    setIsLoading(false);
                    const sceneStr = data.scene_str;
                    const fetchData = async () => {
                      try {
                        const response = await axios.post('/chatinterior/wx_login', {
                            "sceneId": sceneStr
                        });
                        const data = await response.data;
                        if (data.status === 200) {
                            const userInfo = {
                                "email": data.userInfo["openid"],
                                "avatarUrl": (data.userInfo["avatar_url"] === "" || data.userInfo["avatar_url"] === null) ?
                                    "/default.jpg" : data.userInfo["avatar_url"],
                                "password": data.userInfo["password"],
                                "nickName": (data.userInfo["nickname"] === "" || data.userInfo["nickname"] === null )? "微信用户" : data.userInfo["nickname"],
                                "pro": data.userInfo["pro"],
                                "image_balance": data.userInfo["image_balance"],
                                "text_balance": data.userInfo["text_balance"],
                                "memberExpiredDate": data.userInfo["expiration_date"],
                                "expriedDate": new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)
                            }
                            localStorage.setItem('loginInfo', JSON.stringify(userInfo));
                            clearInterval(intervalId); // 清除定时器
                            // 跳转到主页或其他需要登录的页面
                            PubSub.publish('Login Status', true);
                            message.success("登陆成功！", 2)
                            navigate("/chat")
                        }
                      } catch (error) {
                        console.error('Error fetching data:', error);
                        message.error("非常抱歉呐，服务器维护中，请稍后再试！");
                        setReload(true);
                        clearInterval(intervalId); // 清除定时器
                      }
                    };

                    const intervalId = setInterval(fetchData, 2000);
                    setTimeout(() => {
                      // 重新请求二维码数据的逻辑
                        clearInterval(intervalId);
                      setReload(true);
                    }, 120 * 1000);
                }
            }).catch(function (error) {
                message.error('非常抱歉，二维码获取失败，请稍后再试！');
            })
    }

    if (nation === "中国") {
        useEffect(() => {
            if (window.innerWidth > 576) {
                axios({
                    method: "get",
                    url: '/chatinterior/qrcode',
                    data: {
                        "type": "qrcode",
                    },
                }).then(function (response) {
                    const data = response.data;
                    if (data.type === "qrcode") {
                        setQrCode("data:image/png;base64," + data.qrcode);
                        setIsLoading(false);
                        const sceneStr = data.scene_str;
                        const fetchData = async () => {
                            try {
                                const response = await axios.post('/chatinterior/wx_login', {
                                    "sceneId": sceneStr
                                });
                                const data = await response.data;
                                if (data.status === 200) {
                                    const userInfo = {
                                        "email": data.userInfo["openid"],
                                        "avatarUrl": (data.userInfo["avatar_url"] === "" || data.userInfo["avatar_url"] === null) ?
                                            "/default.jpg" : data.userInfo["avatar_url"],
                                        "password": data.userInfo["password"],
                                        "nickName": (data.userInfo["nickname"] === "" || data.userInfo["nickname"] === null) ? "微信用户" : data.userInfo["nickname"],
                                        "pro": data.userInfo["pro"],
                                        "image_balance": data.userInfo["image_balance"],
                                        "text_balance": data.userInfo["text_balance"],
                                        "memberExpiredDate": data.userInfo["expiration_date"],
                                        "expriedDate": new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)
                                    }
                                    localStorage.setItem('loginInfo', JSON.stringify(userInfo));
                                    clearInterval(intervalId); // 清除定时器
                                    // 跳转到主页或其他需要登录的页面
                                    PubSub.publish('Login Status', true);
                                    message.success("登陆成功！", 2)
                                    navigate("/chat")
                                }
                            } catch (error) {
                                console.error('Error fetching data:', error);
                                message.error("非常抱歉呐，服务器维护中，请稍后再试！");
                                setReload(true);
                                clearInterval(intervalId); // 清除定时器
                            }
                        };

                        const intervalId = setInterval(fetchData, 2000);
                        setTimeout(() => {
                            // 重新请求二维码数据的逻辑
                            clearInterval(intervalId);
                            setReload(true);
                        }, 120 * 1000);
                    }
                }).catch(function (error) {
                    message.error('非常抱歉，二维码获取失败，请稍后再试！');
                })
            }
        }, []);
    }

    return (
        <div className="container" style={{marginTop: "100px", padding: '0 15%'}}>
            { window.innerWidth > 576 ? (
                <div>
                    <Row justify="center" className="row" >
                        <Col span={24} align="middle">
                            <Title level={1} id="login-title">{t("login title")}<br/>{t("login content")}</Title>
                        </Col>
                    </Row>
                    <Row className="row" justify="center">
                      <Col align="middle" xs={24} sm={24} md={12} lg={12}>
                          <Card className="login-container">
                              { (nation === "中国") ? (
                                  <div>
                                      <Paragraph style={{color: "white", fontSize: 26}}><WechatOutlined style={{marginRight: "10px", marginBottom: 0, color: "#95ec69"}}/>微信扫码登录</Paragraph>
                                      <div style={{ width: 200, height: 200, backgroundColor: '#232222', borderRadius: 15, border: "solid 1px white", display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10}}>
                                            { isLoading ? (
                                                  <Spin size="large" />
                                              ) : reload ? (
                                                  <div style={{backgroundImage: `url(${qrCode})`}} className="reload-container">
                                                      <div className="overlay">
                                                          <Title level={5}>登录超时</Title>
                                                          <Button type="link" icon={<ReloadOutlined/>} onClick={reloadQrCode}>重新加载</Button>
                                                      </div>
                                                  </div>
                                              ) : (
                                                  <img style={{objectFit: 'cover', width: '100%', height: '100%' }} src={qrCode} />
                                            )}
                                        </div>
                                        <Paragraph style={{color: "white", fontSize: 18, marginTop: 5}}>使用微信扫码关注公众号登录</Paragraph>
                                  </div>
                              ) : (
                                  <div>
                                      <Form
                                          name="loginForm"
                                          initialValues={{ remember: true }}
                                          onFinish={onFinish}
                                          autoComplete="off"
                                        >
                                          <Form.Item
                                            name="username"
                                            rules={[{ required: true, message: t("emailRemind") }]}
                                          >
                                            <Input placeholder={t("email")} style={{ width: '80%' }}/>
                                          </Form.Item>

                                          <Form.Item
                                            name="password"
                                            rules={[{ required: true, message: t("passwordRemind") }]}
                                          >
                                            <Input.Password placeholder={t("password")} style={{ width: '80%' }}/>
                                          </Form.Item>

                                          <Form.Item>
                                            <Button type="primary" htmlType="submit" loading={loginLoading}>
                                              {t("login")}
                                            </Button>
                                          </Form.Item>
                                        </Form>
                                      <div className="divider"></div>
                                      <Button type="primary" icon={<GoogleOutlined />} size="large" onClick={() => login()}>
                                        Google Login
                                      </Button>
                                  </div>
                              )}
                          </Card>
                      </Col>
                  </Row>
                </div>
            ) : (
                <Row justify="center" className="row" >
                    <Col span={24} align="middle">
                        <Title level={window.innerWidth > 576 ? 1 : 4} id="login-title">{t("login title")}<br/>{t("login content")}</Title>
                    </Col>
                    <Col align="middle" xs={24} sm={24} md={12} lg={12}>
                          { (nation === "中国") ? (
                              (window.innerWidth > 576 || showQrCode) ? (
                                  <div>
                                      <Paragraph style={{color: "white", fontSize: 26}}><WechatOutlined style={{marginRight: "10px", marginBottom: 0, color: "#95ec69"}}/>微信扫码登录</Paragraph>
                                      <div style={{ width: 200, height: 200, backgroundColor: '#232222', borderRadius: 15, border: "solid 1px white", display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10}}>
                                          { isLoading ? (
                                              <Spin size="large" />
                                          ) : (
                                              <img style={{objectFit: 'cover', width: '100%', height: '100%' }} src={qrCode} />
                                          )}
                                    </div>
                                    <Paragraph style={{color: "gray", fontSize: 14, marginTop: 5}}>
                                        请确认您知悉
                                        <a href="/terms">
                                            《用户条款》
                                        </a>
                                        和
                                        <a href="/privacy">
                                            《隐私政策》
                                        </a>
                                        。
                                    </Paragraph>
                                    <Paragraph style={{color: "white", fontSize: 18, marginTop: 5}}>使用微信扫码关注公众号登录</Paragraph>
                                  </div>
                              ) : (
                                  <div style={{marginTop: "20px"}}>
                                      <Button type="primary" style={{backgroundColor: "#95ec69", width: "60%", color: "black"}} onClick={handleMobileLogin}>
                                          <WechatOutlined style={{marginRight: "5px", color: "#2e8600"}}/>微信一键登录
                                      </Button>
                                  </div>
                              )
                          ) : (
                              <div>
                                  <Form
                                      name="loginForm"
                                      initialValues={{ remember: true }}
                                      onFinish={onFinish}
                                      autoComplete="off"
                                    >
                                      <Form.Item
                                        name="username"
                                        rules={[{ required: true, message: t("emailRemind") }]}
                                      >
                                        <Input placeholder={t("email")} style={{ width: '90%' }}/>
                                      </Form.Item>

                                      <Form.Item
                                        name="password"
                                        rules={[{ required: true, message: t("passwordRemind") }]}
                                      >
                                        <Input.Password placeholder={t("password")} style={{ width: '90%' }}/>
                                      </Form.Item>

                                      <Form.Item>
                                        <Button type="primary" htmlType="submit" loading={loginLoading}>
                                          {t("login")}
                                        </Button>
                                      </Form.Item>
                                    </Form>
                                  <div className="divider"></div>
                                  <Button type="primary" icon={<GoogleOutlined />} size="large" onClick={() => login()}>
                                    Google Login
                                  </Button>
                              </div>
                          )}
                  </Col>
                </Row>
            )}
        </div>
    )
}